<template>
  <div class="text-left">
    <div>
      <h3 class="mb-6 text-2xl">{{ $t('Integration Instructions') }}</h3>
      <div class="border-0 border-b border-solid border-[#00000014] p-1.5 text-2xl text-black">
        <p class="font-bold">
          1. {{ $t('Download the Plugin') }}
        </p>
        <li>{{ $t('Click the') }} <span class="font-bold">"Download Plugin"</span> {{ $t('button located on the right-hand side of this page') }}.</li>
      </div>
      <div class="border-0 border-b border-solid border-[#00000014] p-1.5 text-2xl text-black">
        <p class="font-bold">
          2. {{ $t('Upload the Plugin') }}
        </p>
        <li>{{ $t('From your Wordpress dashboard, navigate to') + ' ' + $t('Plugins > "Add New"')}}.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-woocomerce-step1.png">
        <li>{{ $t('Click on') }} <span class="font-bold">"Upload Plugin"</span>.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-woocomerce-step2.png">
        <li>{{ $t('Click')}} <span class="font-bold">"Choose File"</span> {{ $t('and select the plugin file you just downloaded') }}.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-woocomerce-step3.png">
      </div>
      <div class="border-0 border-b border-solid border-[#00000014] p-1.5 text-2xl text-black">
        <p class="font-bold">
          3. {{ $t('Install and Activate the Plugin') }}
        </p>
        <li>{{ $t('After selecting the file, click') }} <span class="font-bold">"Install Now"</span>.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-woocomerce-step4.png">
        <li>{{ $t('Once the installation is complete, click') }} <span class="font-bold">"Activate Plugin"</span> {{ $t('to enable it on your site') }}.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-woocomerce-step5.png">
      </div>
      <div class="p-1.5 text-2xl text-black">
        <p class="font-bold">
          4. {{ $t('Setup ShipBlu Integration') }}
        </p>
        <li>{{ $t('Go to the WooCommerce tab in your dashboard and select Settings') }}.</li>
        <li>{{ $t('Navigate to the Shipping tab and find the ShipBlu Integration section') }}.</li>
        <li>{{ $t('Enter your API Key (you can find it at the top of this page)') }}.</li>
        <li>{{ $t('Mark the option') }} <span class="font-bold">"Enable rates at checkout"</span>.</li>
        <li>{{ $t('Click') }} <span class="font-bold">"Save Changes"</span> {{ $t('to apply the settings') }}.</li>
        <img class="w-full my-7" src="@/assets/images/pages/install-woocomerce-step6.png">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      text: '',
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION
    }
  }
}
</script>